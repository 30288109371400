#profileMobile_01 .myAccount_userInfo_profile .list-group-item {
   border: 0;
   background: unset;
}

#profileMobile_01 .myAccount_userInfo_profile label {
   color: var(--color-button);
}

#profileMobile_01 .userInfo_bankDetails_card {
   display: flex;
   align-items: center;
   margin: .5rem 0;
}

#profileMobile_01 .userInfo_bankDetails_bankLogo {
   width: 3rem;
}

#profileMobile_01 .userInfo_bankDetails_bankProfile {
   margin: 0 1rem
}

/* Mobile Start */
#profileMobile_01 .myProfile_container {
   padding-top: 1.5rem;
   padding-bottom: 1.5rem;
   min-height: 100vh;
}

#profileMobile_01 .verify_icon {
   height: 2rem;
   width: 2rem;
   background: #bbb;
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-left: .5rem;
}

#profileMobile_01 .verify_icon.validated {
   background: #07e411;
}

#profileMobile_01 .myAccount_userInfo_profile {
   height: 100%;
   background: #f2f2f2;
   padding: 0 1.5rem;

}

#profileMobile_01 .myAccount_userInfo_profile_row {
   display: flex;
   align-items: center;
   justify-content: space-between;
   border-bottom: 1px solid #ccc;
   padding: 1.5rem 0;
}

#profileMobile_01 .myAccount_userInfo_profile_row img {
   width: 3.5rem;
   margin-right: 1rem;
}

#profileMobile_01 .myAccount_userInfo_profile_row svg {
   color: #000;
}

/* Mobile End */



/* Mobile to Desktop */
@media (min-width: 992px) {
   #profileMobile_01 .myAccount_userInfo_profile {
      height: 100%;
      background: #f2f2f2;
      padding: 0;
   }
}


/*  */
#profileMobile_01 .myProfile_info {
   display: flex;
   flex-direction: column;
}

#profileMobile_01 .myProfile_info_user {
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-bottom: 1rem;
}

#profileMobile_01 .myProfile_info_user img {
   height: 7rem;
   margin-bottom: .25rem;
}

#profileMobile_01 .myProfile_info_reward {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   margin: .5rem 0;
}

#profileMobile_01 .myProfile_info_reward img {
   width: 3.5rem;
}

#profileMobile_01 .myProfile_info_token {
   margin-right: 1rem;
}

#profileMobile_01 .myProfile_info_token,
#profileMobile_01 .myProfile_info_point {
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: .5rem 1.5rem;
   border-radius: .5rem;
}

#profileMobile_01 .myProfile_subTitle {
   margin: .5rem 0;
}

#profileMobile_01 .myProfile_mySetting_row {
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-bottom: .5rem;
   /* padding:1rem; */
   border-radius: .5rem;
}

#profileMobile_01 .myProfile_myAccount {
   margin-top: 1rem;
}

#profileMobile_01 .myProfile_myAccount_row {
   /* display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom:1px solid #ccc;
    padding:1rem; */

   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-bottom: 0.5rem;
   padding: 1rem;
   border-radius: 0.5rem;
}

#profileMobile_01 .myProfile_subTitle+.myProfile_myAccount_row {
   border-top-left-radius: .5rem;
   border-top-right-radius: .5rem;

}

#profileMobile_01 .myProfile_myAccount_row:last-child {
   border-bottom-left-radius: .5rem;
   border-bottom-right-radius: .5rem;
}

#profileMobile_01 .myProfile_myAccount_row img {
   width: 3.5rem;
   margin-right: 1rem;
}


#profileMobile_01 .myProfile_container .form-check-input.is-invalid:focus,
.myProfile_container .was-validated .form-check-input:invalid:focus {
   box-shadow: none;
}

#profileMobile_01 .myProfile_container .form-switch .form-check-input:checked {
   background-position: right center;
   background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e'), linear-gradient(to right, #5cf25e, #00c225) !important;
}

#profileMobile_01 .myProfile_container .form-switch .form-check-input {
   width: 4em;
   height: 2em;
}

#profileMobile_01 .myProfile_container .form-check-input.is-invalid,
.myProfile_container .was-validated .form-check-input:invalid {
   border-color: rgba(0, 0, 0, .25);
}


#profileMobile_01 .myProfile_userInfo {
   display: flex;
   align-items: center;
}

#profileMobile_01 .myProfile_userInfo label {
   margin-right: .5rem;
}

/* ------------------------- */
.myQRCode_body{
   min-height: 100vh;
}
.myQRCode_body .shareQR_title {
   /* text-align: start; */
   padding-left: 1.5rem;
}

.myQRCode_body .shareQR_info {
   /* background: #f2f2f2; */
   padding: 2rem 1rem;
   border-radius: .5rem;
   margin:2rem;
}

.myQRCode_body .shareQR_qrCode div {
   flex-direction: row;
}

.myQRCode_body .shareQR_shareMethod img {
   height: 6rem;
}