@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&family=Noto+Sans+SC:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@500&display=swap');

@font-face {
  font-family: 'DFT_LX9';
  src: local('DFT_LX9'), url(./fonts/DFT_LX9.TTF) format('truetype');
}

@font-face {
  font-family: 'AvertaStandard-Regular';
  src: local('AvertaStandard-Regular'), url(./fonts/AvertaStandard-Regular.ttf) format('truetype');
}



/*  -------  Set Variable Start  -------  */

:root {
  /* color-scheme: light only; */
  /* ------- Color Variable ------- */
  --color-primary: #010126;
  --color-secondary: #00a4ff;
  --color-button: #0166D1;
  --color-title: #fff;
  --color-text: #fff;
  --color-footer: #000;
  --color-active: #1677ff;
  /* ------- Font Variable ------- */

  /* Size */
  --h1-font-size: 2rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.17rem;
  --h4-font-size: 1rem;
  --h5-font-size: 0.83rem;
  --h6-font-size: 0.625rem;
  --btn-font-size: 0.7rem;

  /* Family */
  /* --font-family: poppins, sans-serif; */
  /* --font-family: 'DFT_LX9'; */

  /* idnhero */
  --font-family: 'Lato', sans-serif;


  /* subohk */
  /* --font-family: 'Noto Sans', sans-serif; */

  /* close btn */
  --btn-close-bg-white: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='white' d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");

}

/*  -------   Set Variable End  -------  */


/*  -------   Default  -------   */

* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

html,
body {
  font-size: 10px !important;
  position: relative;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;

}

a {
  text-decoration: inherit;
  color: inherit;
}

a:hover {
  color: unset;
}

.nav-link {
  color: unset;
}

.navbar-toggler:focus {
  box-shadow: none;
}

button {
  border: 0
}

input[type="submit"],
input[type="button"] {
  border: 0;
}

input,
select {
  height: 3.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 0rem;
}

.card {
  background: unset;
}

.card-header {
  background-color: unset;
  color: unset;
}

.card-body {
  color: unset;
}

.form-check-input:focus {
  box-shadow: inherit;
}

.form-control:focus,
.form-select:focus {
  border-color: 0;
  box-shadow: inherit;
  /* border-color:red; */
}

.form-label .form_required {
  /* color: red; */
  margin-left: .25rem;
}

.accordion-button,
.accordion-button:not(.collapsed) {
  background-color: inherit;
  color: inherit;
}

.accordion-button:focus {
  border-color: 0;
  box-shadow: inherit;
}

.cursor-pointer {
  cursor: pointer;
}

/* .nav-tabs .nav-link:hover{
  border:none ;
} */

.dropdown-menu {
  color: inherit;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: inherit;
}


/* Font Default Css Start*/

/* .font_h1 {
  font-size: var(--h1-font-size);
  font-family: var(--font-family);

}

.font_h2 {
  font-size: var(--h2-font-size);
  font-family: var(--font-family);

}

.font_h3 {
  font-size: var(--h3-font-size);
  font-family: var(--font-family);

}

.font_h4 {
  font-size: var(--h4-font-size);
  font-family: var(--font-family);

}

.font_h5 {
  font-size: var(--h5-font-size);
  font-family: var(--font-family);
  font-weight: 500;

}

.font_h6 {
  font-size: var(--h6-font-size);
  font-family: var(--font-family);

}

.font_button {
  font-size: var(--btn-font-size);
  font-family: var(--font-family);
} */


/* Font Default Css End*/

/* Color Default Css Start*/

/* .color_primary {
  color: #fff;
  background: #010126;
}

.color_secondary {
  color: #fff;
  background: #00a4ff;
}

.color_text {
  color: #fff;
  background: transparent;
}

.color_button {
  color: #fff;
  background: #0166D1;
}

.color_footer {
  color: #fff;
  background: var(--color-footer);
}

.color_active {
  color: #fff;
  background: var(--color-active);
} */

.form-check-input:checked {
  border-color: transparent;
  background-color: green;
}

.form-switch .form-check-input:checked {
  border-color: transparent;
  background-color: #0000002b;
  border: 0
}

.list-group-item {
  color: inherit
}

button:focus-visible {
  outline: 0;
}

.form-check-input:focus {
  border: 1px solid rgba(0, 0, 0, .25);

}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e")
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent;
}

.card>.list-group:last-child {
  border-bottom-width: 1px;
  border-bottom-right-radius: initial;
  border-bottom-left-radius: initial;

}

.card>.list-group:first-child {
  border-top-width: 1px;
}

.scrollToTopButton {
  position: fixed;
  right: 1.5rem;
  bottom: 7rem;
  font-size: 2rem;
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

/* Color Default Css End*/

.App,
.page {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  font-family: var(--font-family)
}

.header_beforeLogin+.page {
  margin-top: 5rem;
}

.header_afterLogin+.page {
  margin-top: 13.6rem;
}

.header_afterLogin.headerEnableLoyalty+.page {
  margin-top: 15.6rem;
}

#header_03.header_afterLogin+.page {
  margin-top: 18rem;
}

#header_03.header_afterLogin.headerEnableLoyalty+.page {
  margin-top: 20rem;
}

.header_afterLogin+#profileMobile.page {
  margin-top: 5rem;
}

#header_03.header_afterLogin.headerEnableLoyalty+#profileMobile.page {
  margin-top: 5rem;

}

/* body{
  background: #000 ;
} */

.img_rounded {
  border-radius: .5rem;
}

.lazy-load-image-background.blur.lazy-load-image-loaded {
  border-radius: inherit;
}


.floating_button {
  position: fixed;
  right: 3rem;
  bottom: 1.5rem;
  width: 5rem;
  height: 5rem;
  z-index: 5;
  border-radius: 50% !important;
}

.floating_button svg {
  font-size: 2rem
}

.section.pageTitle {
  padding: 1.25rem;
}

/* ::-webkit-scrollbar {
  background-color: transparent;
  width: 0.85rem;
  height: 0.25rem;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
} */

::-webkit-scrollbar {
  -webkit-appearance: none;
  background-color: transparent;
  width: 0.85rem;
  height: 0.25rem;
}

/* ::-webkit-scrollbar:vertical {
  width: 12px;
}
::-webkit-scrollbar:horizontal {
  height: 12px;
} */
::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
  border-radius: 10px;

}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
}

/* ::-webkit-scrollbar {
  background-color: transparent;
  width: 0.85rem;
  height: 0.25rem;
}

::-webkit-scrollbar-thumb {
  background: red;
} */

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  word-break: keep-all;
  vertical-align: middle;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/* Responsive */

@media (min-width: 576px) {

  html,
  body {
    font-size: 11px !important;
  }
}


@media (min-width: 768px) {

  html,
  body {
    font-size: 12px !important;
  }
}

/* Mobile to Desktop */
@media (min-width: 992px) {

  html,
  body {
    font-size: 11px !important;
  }


  .header_beforeLogin+.page,
  .header_afterLogin+.page,
  .header_afterLogin.headerEnableLoyalty+.page,
  #header_03.header_afterLogin+.page,
  #header_03.header_afterLogin.headerEnableLoyalty+.page {
    margin-top: 6rem;
  }


  .header_beforeLogin.header_newRowMenu+.page,
  .header_afterLogin.header_newRowMenu+.page,
  #header_03.header_afterLogin.header_newRowMenu+.page {
    margin-top: 9.25rem;
  }



  input,
  select {
    height: auto;
  }

  ::-webkit-scrollbar {
    background-color: transparent;
    width: 0.85rem;
    height: 0.85rem;
  }

  ::-webkit-scrollbar-thumb {
    background: #ccc;
  }



}



@media (min-width: 1200px) {

  html,
  body {
    font-size: 12.5px !important;
  }
}

@media (min-width: 1400px) {

  html,
  body {
    font-size: 14px !important;
  }
}


@media (min-width: 1600px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1400px !important;
  }
}


/* below mobile size width 345px */

@media (max-width: 375px) {

  html,
  body {
    font-size: 9px !important;
  }
}

@media (max-width: 340px) {

  html,
  body {
    font-size: 7px !important;
  }
}

@media (max-width: 250px) {

  html,
  body {
    font-size: 5px !important;
  }
}


@media (max-width: 185px) {

  html,
  body {
    font-size: 4px !important;
  }
}


@media (max-width: 992px) {

  .form-label,
  label {
    font-size: 1.3rem;
  }

  .subTitle {
    font-size: 1.5rem;
  }

  p {
    font-size: 1.25rem;
  }

  input.font_button,
  button.font_button:not(header button.font_button) {
    font-size: 1.25rem;
  }

  .font_tab_pills,
  .font_tab {
    font-size: 1.25rem;
  }




}

.react-datepicker-wrapper {
  width: 100%;
}

/* designer tool add - change all merchant css */

.header_topBar_wrap .header_banking_info .header_balance,
.header_topBar_wrap .header_bottom_row .header_balance {
  background: #e7e7e7;
  color: #000;
}

.header_topBar_wrap .header_bottom_row .header_balance.coupon_balance {
  background: #e7e7e7;
  color: #000;
  cursor: pointer;
}

/* .header_banking_info_left .header_balance .header_balance_amount span{
} */
.mslot_container {
  margin: 4rem 10rem;
}

.mslot_container_m {
  margin: 0 5rem 1rem;
}

.mslot_container .mslot_wrap {
  padding: 2rem;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
}

.dropdown_content {
  background: rgb(0, 0, 0, 0.8);
  color: #fff;
}

.dropdown_content_container a {
  filter: brightness(0.5);
}

.main_gameSelection {
  padding-top: 1rem;
  padding-bottom: 1rem;
}


.form_required_input,
.register_error_msg,
.forgotPassword_error_msg,
.forgotUsername_error_msg,
.error_msg,
.form_required {
  color: red
}

.register_error_msg .forgotPassword_error_msg,
.forgotUsername_error_msg {
  font-size: .8rem;
}

.register_btnSendCode,
.register_btnVerify,
.btnSendCode,
.forgotUsername_btnVerify,
.forgotPassword_btnVerify {
  border-radius: .25rem;
}


.wallet_btnAmount button {
  border-radius: 0.375rem;
}

button.alertMessage_btnClose {

  border-radius: .25rem;

}

.wallet_btnSubmit.btnSearch {
  border-radius: .35rem;
}

.vip_container {
  margin-top: 2rem;
  margin-bottom: 2rem;
}


.walletBar_btnLogout {
  padding: 0.5rem 0;
}

.header_sideBar_topBar {
  padding: 1.25rem;
}


.main_gameSelection .main_gameSelection_menu {
  margin-bottom: 0.5rem;
  padding: 1rem;
}

.myProfile_mySetting_row {
  padding: 1rem;
}

.dynamic2 img {
  width: 100%;
}

.contact_back_d .contact_back_d_item {
  padding: 0.75rem 1rem
}

.banking_dropdown li,
.claim_dropdown li {
  border-radius: .5rem;
  margin: .5rem 0;

}

.btn_banking .banking_dropdown,
.btn_claim .claim_dropdown {
  background: #f1f1f1;
  padding: 0.15rem .6rem;
  width: 15rem;
  font-size: .8rem;
}


.announcement_header {
  padding: 1.25rem 1rem;
  position: relative;
}


.banking_dropdown li,
.claim_dropdown li {
  border-radius: .5rem;
  padding: 0.5rem .8rem;

}

.walletBar_userVerification {
  border-radius: 1rem;
}

.walletBar_point {
  background: #e7e7e7;
}

.walletBar_walletUserQrInfo {
  background: #e7e7e7;
}

.card_container {
  padding: 0.8rem 0.5rem;
  position: relative;
}

.header_sideBar_logo {
  margin: .5rem;
  width: 65%;
}

.header_logo img {
  height: 2.8rem;
  width: auto;
}

.header_d .header_logo img {
  height: 3.5rem;
  width: auto;
}

.mslot_container_m .mslot_userInfo input {
  border-radius: 1rem;
}


.main_game_wrap_vertical .main_game_container a {
  padding: 0.5rem 0.25rem;
  padding: 0.5rem;
}


.btn_gamewallet .game_wallet_dropdown {
  background: #f1f1f1;
  color: #000;
}

.game_wallet_dropdown table tbody tr {
  background: #e6e6e6;
  color: #000;
}

.gameWallet_accordion.accordion {
  margin-top: 1rem;
  max-height: 30rem;
  overflow: hidden;
}

.gameWallet_accordion .accordion-item,
.gameWallet_accordion .accordion-item:first-of-type,
.gameWallet_accordion .accordion-item:last-of-type {
  margin: 0 0 .75rem;
  border-radius: .5rem;
  background-color: transparent;
  border: 0;
}

.gameWallet_accordion .accordion-button {
  padding: .5rem .85rem .5rem 1rem;
  font-size: .9rem;
  background: #fff;
  border-radius: .5rem;
  justify-content: space-between;
}

.gameWallet_accordion .accordion-button div:nth-child(1) {
  width: 50%;
}



.game_wallet_dropdown table tbody tr td:first-child {
  width: 45%;
}

#gameWalletModal .game_wallet_dropdown table tbody tr td:first-child {
  width: 50%;


}

.gameWallet_accordion .accordion-button:not(.collapsed) {
  box-shadow: none;
}

.gameWallet_accordion .accordion-body {
  padding: 0;
}

#header_03 .header_banking_info .gameWallet_accordion .accordion-body img,
#header_01 .header_banking_info .gameWallet_accordion .accordion-body img {
  width: 1.45rem;
  height: 1.45rem;
}



#gameWalletModal .gameWallet_accordion .accordion-button {
  padding: 1rem .8rem 1rem 1rem;
  font-size: 1rem;
}

.btn_gamewallet .game_wallet_dropdown:after {
  border-bottom: 8px solid #f1f1f1;
}

#gameWalletModal .modal-body {
  background: #f1f1f1
}

#gameWalletModal .game_wallet_dropdown table tbody tr {
  background: transparent;
  color: #000;
}

.btn_banking .banking_dropdown,
.btn_claim .claim_dropdown {

  background: #f1f1f1;
  color: #000;
  transform: translate(-50%, 0);


}

.btn_banking .banking_dropdown:after,
.btn_claim .claim_dropdown:after {
  border-bottom: 8px solid #f1f1f1;
}

.displayQR_row label {
  color: red;
}

.bottomMenuFooter {
  margin-bottom: 6rem;
}

.wallet_btnAmount button {
  border: 1px solid #ced4da;

}

.myAccount_bankingDetails_row {
  background: #fff;
  color: #000;
}


.register_form_reminder_title,
.forgotPassword_form_reminder_title,
.forgotUsername_form_reminder_title {
  color: red;
}

.input_error_msg {
  color: red;
}

.forgot_content .forgot_content_username,
.forgot_content .forgot_content_password {
  color: red;
}

.login_form_step {
  margin: 3rem 0 0 0;
}

.shareLink_button {
  padding: 0.5rem 1rem;
  border-radius: 25px;
  font-size: .8rem;
}

.gameProviderTab .gameProviderTab_selection {
  filter: grayscale(1) opacity(0.5);

}

.count_container span {
  background: #f1f1f1;
  color: #000
}

.count_box p:first-of-type {
  background: #f1f1f1;
  color: #000
}

.myQRCode_body .shareQR_info {
  background: #f2f2f2;
}


#promoClaimModal .filterTab {
  background: rgba(231, 231, 231, 1);
  border-radius: 20px 20px 20px 20px;
}

#promoClaimModal .filterTab .filterTab_selection {
  border: 0;
  border-radius: 20px 20px 20px 20px;
}


.wallet_btnAmount button.active {
  border: 0;
}

.myAccount_bankingDetails_table {
  border: 1px solid #ccc;

}

/* MY FRIEND */
.friendList_group_title {
  padding: .75rem 2rem;
  background: #e7e7e7;
  color: #000;
}

.friendList_group_item {
  border-radius: .5rem;
  border: 1px solid #e7e7e7;
  padding: .75rem 1rem;
  border-right: 0;
  border-left: 0;
}

.friendDetail_group_item {
  border-radius: .5rem;
  border: 1px solid #e7e7e7;
  padding: .75rem 1rem;
  margin-bottom: 1rem;



}

.searchFriend_container {
  /* display: flex;
  align-items: center;
  width: 100%; */
  border-radius: .5rem;
  border: 1px solid #e7e7e7;
  padding: 1rem;
  margin-bottom: 1rem;
}

#myQRcodeModal,
#myQRcodeModal .shareQR_shareMethodWrap {
  color: #000;
}

.floatingIcon_body img {
  max-width: 150px;
  max-height: 150px;
}

.realNameVerification_container .realNameVerification_viewSample_button {
  border-radius: 25px;
}

.search_container input {
  border-radius: 2rem;
}

.myAccount_userInfo_profile .verifyNow_button {
  border-radius: 25px;
}


.deposit_transferContainer {
  padding: 1rem;
  padding-bottom: 0;
  border: 1px solid #ced4da;
  border-radius: .5rem;
  margin-bottom: .5rem;
}


.mslot_container .mslot_userInfo input {
  border-radius: 1rem;
  padding: 0.25rem 3rem 0.25rem 1rem;
  background: #fff;
}

.history_paginationButton button {
  padding: .25rem .85rem;
  border-radius: 1rem;
}

.mslot_container .mslot_imageWrap img {
  height: 15rem;
}


/* ----------------------------------- */
.sponsor_body {
  background: rgba(231, 231, 231, 1);
  padding-top: 1rem;
}

.sponsor_partnetship_container {
  background: #fff;
  filter: drop-shadow(2px 3px 3px rgb(155, 155, 155, 1));
  padding: 0 2rem 3rem;
  border-radius: 1rem;
}

.sponsor_partnetship_section {
  padding: 3rem 0;
}

#sponsor_profile .swiper {
  filter: drop-shadow(2px 3px 3px rgb(155, 155, 155, 1));
  border-radius: 1rem;
}

#sponsor_partnership img {
  border-radius: .5rem;
}

.sponsor_profile_title,
.sponsor_partnetship_title {
  font-weight: 600;
}

.sponsor_partnetship_desc {
  color: #999;
}

.sponsor_profile_subTitle {
  color: red;
}

.header_point span:nth-child(2) {
  color: #666;
}


.gameWallet_accordion .gameWallet_accordion_icon {
  position: relative;
  margin-left: auto;
  padding: 0.25rem .45rem;
  border-radius: .25rem;
  background: linear-gradient(to right, #6d2c90, #9f60a6);
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .5s;
  color: #fff;
}

#gameWalletModal .gameWallet_accordion .gameWallet_accordion_icon {
  width: 2rem;
  height: 2rem;
}

.gameWallet_accordion .gameWallet_accordion_icon svg {
  font-size: .95rem;
  position: relative;
  z-index: 2;
  /* color: #fff; */
  top: 0.5px;
  left: 0px;
}

#header_01 .header_topBar_wrap .header_banking_info .header_balance_icon .gameWallet_accordion_icon svg,
#header_02 .header_topBar_wrap .header_banking_info .header_balance_icon .gameWallet_accordion_icon svg,
#header_03 .header_topBar_wrap .header_banking_info .header_balance_icon .gameWallet_accordion_icon svg {
  margin: 0;
  padding: 0;

}

.gameWallet_accordion .accordion-button::after {
  display: none;
}

.gameWallet_accordion .accordion-button:not(.collapsed) .gameWallet_accordion_icon {
  transform: rotate(-180deg);
}


.header_WalletIcon {
  width: 25px;
  height: 25px;
  margin-right: .25rem;
}


.announcement_header::before {
  content: '';
  width: 93%;
  height: .5px;
  background: transparent;
  position: absolute;
  left: 4%;
  bottom: 0;
}


.bgSectionVip {
  background: url('https://storage.googleapis.com/win22-images.imbaweb.com/background/bg_vip.png');
  background-size: cover;
  background-repeat: no-repeat;
}


.selectProviderContainer .game_wallet_dropdown {
  background: #f1f1f1;
  padding: .5rem;
  margin-top: .5rem;
  border-radius: 5px;
  filter: drop-shadow(2px 3px 3px rgba(0, 0, 0, .3));
}

.selectProviderContainer .game_wallet_dropdown:after {
  border-bottom: 8px solid #f1f1f1;
}


.more_menu .dropdown_more_content {
  background: #f2f2f2;
  color: #000;
  box-shadow: 5px 9px 8px 2px rgba(0, 0, 0, 0.2);
}

.btnAfterVerify {
  background: #00013e;
  color: #fff;
  border: 1px solid #f6f40d;
}


.swiper-pagination-bullet-active {
  background: var(--color-theme);
}

.header_point svg {
  cursor: pointer;
}


.wallet_container_m .realNameVerification_title {
  border-left: 3px solid;
}


.section_title img {
  max-width: 2.5rem;
  margin-right: 0.5rem;
  width: auto;
  height: 2rem;
}


.speakerAnnouncement_iconContainer img {
  width: auto;
  height: 1.75rem;
}

.main_gameSelection .main_gameSelection_menu img {
  width: auto;
  max-height: 3rem;
  border-radius: inherit;
}

.main_gameSelection_vertical .main_gameSelection_menu img {
  width: auto;
  max-height: 7rem;
  border-radius: inherit;
}

.contact_back_d.card {
  background: transparent;
  border: 0 !important;
  width: 15rem;
}

.myAccount_bankingDetails_table {
  /* border: 1px solid #ccc; */
  margin-bottom: 2rem;
  /* background: #f2f2f2; */
  color: #000;
}

.myAccount_notification_title_row {
  color: #000;
  border-bottom: 1px solid #ccc;
}

.myAccount_notification_title {
  border: 1px solid #ccc;
}

.mslot_container .mslot_title {
  border-bottom: 1px solid;
}

.mslot_container .mslot_title svg {
  color: red;
}


.paginationNumber:not(.paginationNumber.active) {
  background: transparent;
}


.card_content_wrap {
  padding-bottom: .5rem;
}

.announcement_header img {
  width: 10rem;
}


.header_sideBar .btn-close {
  color: inherit;
  opacity: 1;
  font-size: 1.5rem;
  background: transparent;
  position: relative;
}


.header_menu_dropdownItem {
  color: inherit;
}

.dropdown_more_content_container a {
  filter: brightness(.5);
}


.walletBar_selection img {
  height: 2.5rem;
  width: auto;
  margin-right: 1rem;
}


.header_sideBar_menu .header_menu_content .header_text {
  font-size: 1.5rem;
  text-transform: capitalize;
}


.iframeContainer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99;
  background: #fff;
  min-height: 70vh;
}

.closeIframe {
  padding: 1rem;
  position: absolute;
}

.closeIframe button {
  font-size: 1.25rem;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}